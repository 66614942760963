import React from "react";
import { useRouter } from "next/router";
import Image from "next/image";
import { Button } from "@pepdirect/ui/button";
import { getCloudflareLoader } from "@pepdirect/helpers/cloudflareImages";
import { GqlModule, ModuleConfig } from "types/module";
import { endpoints } from "config";
import "./moduleBundle.module.scss";
import st from "./moduleBundle.module.scss";

export function ModuleBundle({
  config,
  data,
}: {
  config: ModuleConfig;
  data: GqlModule;
}): JSX.Element {
  const router = useRouter();

  const originalImgSrc = (pos: number): string => {
    const { thumbnailItems } = data;
    const item = thumbnailItems.find((x) => x.position === pos);
    return item?.imgUrl || "";
  };

  // TODO: move to separate hook file when we have more modules
  const clickHandler = () => {
    const { enableLinkOverride } = config;
    const { urlOverride, storySlug } = data;

    if (enableLinkOverride && (urlOverride || "")?.length > 0) {
      window.location.href = urlOverride || "";
    } else {
      router.push("/kit/[slug]", `/kit/${storySlug}`);
    }
  };

  const { title1, cta1 } = data;

  const imageSrc = originalImgSrc(0);

  return (
    <div
      className={st.moduleBundle}
      onClick={clickHandler}
      onKeyPress={clickHandler}
      role="link"
      tabIndex={0}
    >
      <h3 className={st.title}>{title1}</h3>
      <figure className={st.imageContainer}>
        {imageSrc && (
          <Image
            className={st.image}
            alt={title1 || ""}
            src={imageSrc}
            width={340}
            height={226}
            loader={getCloudflareLoader(endpoints.productsImgCdn)}
            style={{
              maxWidth: "100%",
              height: "auto",
            }}
          />
        )}
      </figure>
      <Button tabIndex={-1} fullWidth>
        {cta1}
      </Button>
    </div>
  );
}
