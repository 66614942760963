import React, { useContext } from "react";
import Image from "next/image";
import { endpoints } from "config";
import { ModalContext } from "context/modal";
import { useIsMobile } from "@pepdirect/helpers/useIsMobile";
import { useRouterWithRedirect } from "hooks/useRouterWithRedirect";
import { Button } from "@pepdirect/ui/button";
import { Heading } from "@pepdirect/ui/heading";
import {
  logSignIn,
  logSignUp,
  logEvent,
} from "@pepdirect/helpers/analyticsLogger";
import { EVENT_NAMES, LOGIN_LOCATION_ENUM } from "@pepdirect/shared/types";
import { getCloudflareLoader } from "@pepdirect/helpers/cloudflareImages";
import st from "./checkoutSubscriptionModal.module.scss";
import { UserContext } from "context/user";

export function CheckoutSubscriptionModal(): JSX.Element {
  const { currentUserId } = useContext(UserContext);
  const { open, close } = useContext(ModalContext);
  const isMobile = useIsMobile();

  const { pushPreserveRedirect } = useRouterWithRedirect();

  const goToSignIn = () => {
    logEvent(null, EVENT_NAMES.cartLoginRedirect);
    logSignIn(LOGIN_LOCATION_ENUM.checkoutSubscriptionModal);
    if (isMobile) {
      close(currentUserId);
      pushPreserveRedirect("/sign-in");
    } else {
      open("LOGIN_MODAL");
    }
  };

  const goToSignUp = () => {
    close(currentUserId);
    pushPreserveRedirect("/sign-up");
    logSignUp(LOGIN_LOCATION_ENUM.checkoutSubscriptionModal);
  };

  return (
    <div className={st.wrapper}>
      <Image
        alt="shop logo"
        src={`${endpoints.cdn}/static-images/pantry-shop-icons/dark-large-pantry-shop-logo.svg`}
        loader={getCloudflareLoader(endpoints.cdn)}
        width="160"
        height="33"
        className={st.logo}
        style={{
          maxWidth: "100%",
          height: "auto",
        }}
      />
      <div className={st.content}>
        <div className={st.block}>
          <div className={st.title}>
            <Heading level="h2" size="l">
              New to Pantry Shop?
            </Heading>
          </div>
          <div>
            Create an account for fast and simple checkout on your next order.
          </div>
          <ul className={st.list}>
            <li>View all orders in the account dashboard.</li>
            <li>Save your preferred shipping address.</li>
            <li>Add multiple payment methods.</li>
          </ul>
          <div className={st.button}>
            <Button fullWidth onClick={goToSignUp}>
              Create An Account
            </Button>
          </div>
        </div>
        <div className={st.block}>
          <div className={st.desc}>
            Guest checkout is not available for orders with subscription items.
          </div>
          <div className={st.note}>
            <span>Already have an account? </span>
            <Button type="text" onClick={goToSignIn}>
              Log in now
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
